import React from "react";
import styled from "@emotion/styled";
import { Color } from "../utils/color";
import { Link } from "gatsby";

import { Breadcrumbs } from "../components/breadcrumbs/breadcrumbs";
import { Layout } from "../components/layout/layout";

const CompanyPage = () => {
  return (
    <Layout title="会社案内 | LIME" jpUrl="/company/" enUrl="/en/company/">
      <Breadcrumbs>
        <Link to="/">ホーム</Link>
        <span>/</span>
        <Link to="/company/">会社案内</Link>
        <span>/</span>
      </Breadcrumbs>
      <SSection>
        <p>会社案内</p>
      </SSection>
    </Layout>
  );
};

export default CompanyPage;

const SSection = styled.section`
  background-color: ${Color.light};
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
